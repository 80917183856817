angular
  .module('gym.components')
  .directive('editFormField', ['$window', ($window) ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/edit_form_field.html.slim')
    scope:
      field: '='
      form: '='
      units: '='
      minDate: '='
      errors: '='
      disabled: '='
      compact: '='
      hideLabel: '='
      optional: '='
      variant: '@'
      checkboxInline: '='
      useNativeDatepicker: '='
    controller: ['$rootScope', '$scope', ($rootScope, $scope) ->
      $scope.users = $rootScope.gym?.users
      $scope.locations = $rootScope.gym?.locations.filter((location) -> location.active)
      $scope.currency = $rootScope.gym?.currency_symbol || window.DATA.currency
      $scope.id = Math.floor(Math.random() * 100000)

      $scope.getPlaceholder = ->
        if $scope.hideLabel && $scope.optional
          "#{$scope.field.label} (optional)"
        else
          $scope.field.label

      $scope.getInputType = ->
        input = document.createElement('input')
        input.setAttribute 'type', 'date'
        if input.type isnt 'text'
          return 'date'
        else
          return 'text'

      $scope.inputType = $scope.getInputType()

      $scope.isMobile = ->
        $window.innerWidth < 768

      angular.element($window).bind('resize', ->
        $scope.$apply()
      )

      $scope.$on('$destroy', ->
        angular.element($window).unbind('resize')
      )
    ]
  ])
