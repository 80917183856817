import _ from 'underscore';

export const MERGE_TAG_LIST = [
  'contact-first-name',
  'contact-last-name',
  'contact-email',
  'contact-phone-number',
  'contact-address',
  'what-contact-interested-in',
  'how-contact-contacted-us',
  'how-contact-heard-about-us',
  'last-attended-on',
  'next-upcoming-payment-amount',
  'next-upcoming-payment-date',
  'failed-payments-count',
  'failed-payments-total-amount',
  'business-name',
];

export const MEMBER_PORTAL_MERGE_TAG_LIST = ['member-portal-url', 'member-portal-pin'];

export const MOBILE_APP_MERGE_TAG_LIST = ['mobile-app-password-reset-link', 'mobile-app-username'];

export const MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST = [
  'mobile-app-invite-link',
  'mobile-app-ios-app-store-link',
  'mobile-app-google-app-store-link',
];

export const MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST = [
  'mobile-app-invite-url',
  'mobile-app-ios-app-store-url',
  'mobile-app-google-app-store-url',
];

export const PAYMENT_MERGE_TAGS = [
  'payment-date',
  'payment-failure-date',
  'payment-failure-reason',
  'payment-status',
  'payment-amount',
  'invoice-number',
];

export const CREDIT_VOUCHER_MERGE_TAGS = ['payment-amount-before-credit', 'credit-applied-amount'];

export const MEMBERSHIP_MERGE_TAGS = [
  'membership-name',
  'membership-start-date',
  'membership-expiration-date',
  'membership-upfront-payment-date',
  'membership-recurring-payments-start-date',
  'membership-upfront-payment-amount',
  'membership-recurring-payments-amount',
  'membership-recurring-payments-frequency',
  'membership-final-payment-amount',
  'membership-final-payment-date',
  'finite-membership-remaining-payments-count',
  'membership-cancellation-fee-amount',
  'membership-cancellation-fee-due-date',
];

export const BOOKING_MERGE_TAGS = [
  'booking-date',
  'booking-start-time',
  'booking-end-time',
  'instructor-name',
  'location-name',
  'event-name',
  'event-description',
  'booking-cancellation-policy',
  'google-calendar-link',
  'meeting-url',
  'meeting-instructions',
];

export const GRADING_EVENT_MERGE_TAGS = [
  'grading-name',
  'grading-date',
  'grading-time',
  'grading-datetime',
  'grading-rsvp-due',
  'grading-rsvp-link',
  'grading-next-rank',
  'grading-current-rank',
  'grading-style',
  'grading-fee-amount',
  'grading-fee-due',
];

export const GRADING_MERGE_TAGS = ['grading-date', 'previous-rank-name', 'new-rank-name'];

export const STYLE_MERGE_TAGS = [
  'style-name',
  'current-rank-name',
  'next-rank-name',
  'most-recent-grading-date',
];

export const SUSPENSION_MERGE_TAGS = ['suspension-start-date', 'suspension-end-date'];

export const RECURRING_BOOKING_MERGE_TAGS = [
  'recurring-booking-event-name',
  'recurring-booking-instructor-name',
  'recurring-booking-location-name',
  'first-booked-event-date',
  'first-booked-event-start-time',
  'first-booked-event-end-time',
];

export const LATE_CANCELLED_BOOKING_MERGE_TAGS = [
  'late-cancelled-booking-date',
  'late-cancelled-booking-start-time',
  'late-cancelled-booking-event-name',
  'late-cancelled-booking-location-name',
  'late-cancelled-booking-instructor-names',
];

export const WAITLIST_ATTENDEE_MERGE_TAGS = [
  'waitlisted-event-name',
  'waitlisted-event-date',
  'waitlisted-event-start-time',
  'waitlisted-event-end-time',
  'waitlisted-event-instructor-name',
  'waitlisted-event-location-name',
];

export const PERFORMED_WORKOUT_MERGE_TAGS = [
  'workout-name',
  'workout-performed-on',
];

export const ALL_MERGE_TAG_LIST = _.uniq(
  [].concat(
    MERGE_TAG_LIST,
    MEMBER_PORTAL_MERGE_TAG_LIST,
    MOBILE_APP_MERGE_TAG_LIST,
    MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST,
    MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST,
    PAYMENT_MERGE_TAGS,
    CREDIT_VOUCHER_MERGE_TAGS,
    MEMBERSHIP_MERGE_TAGS,
    BOOKING_MERGE_TAGS,
    GRADING_EVENT_MERGE_TAGS,
    GRADING_MERGE_TAGS,
    STYLE_MERGE_TAGS,
    SUSPENSION_MERGE_TAGS,
    RECURRING_BOOKING_MERGE_TAGS,
    LATE_CANCELLED_BOOKING_MERGE_TAGS,
    WAITLIST_ATTENDEE_MERGE_TAGS,
    PERFORMED_WORKOUT_MERGE_TAGS,
  ),
);
