angular
  .module 'shared'
  .directive 'templateRequiresObjectMessage', ->
    restrict: 'E'
    scope:
      template: '='
    controller: ($scope) ->
      $scope.sequenceType = () ->
        switch
          when $scope.template.requires_booking_object
            'Booking'
          when $scope.template.requires_membership_object
            'Membership'
          when $scope.template.requires_invoice_object
            'Payment'
          when $scope.template.requires_member_style_object
            'Style'
          when $scope.template.requires_member_grading_object
            'Grading'
          when $scope.template.requires_contact_suspension_object
            'Suspension'
          when $scope.template.requires_recurring_booking_detail_object
            'Recurring booking'
          when $scope.template.requires_late_booking_cancellation_object
            'Booking late-cancelled'
          when $scope.template.requires_waitlist_attendee_object
            'Contact added to event waitlist'
          when $scope.template.requires_performed_workout_object
            'Performed workout'

    template: '<div class="alert alert-danger is-sm">
      This template can only be used in a {{sequenceType()}} sequence. Please choose another template.
    </div>'
