angular
  .module 'gym.components'
  .directive 'membershipFirstStep', ->
    restrict: 'EA'
    template: require('templates/components/membership/membership_first_step.html.slim')
    scope:
      contact: '='
      isClassesLimitValid: '&'
      isClassesIntervalValid: '&'
      membership: '='
      newMembershipPlan: '='
      membershipPlans: '='
      membershipPlanCategories: '='
      eventTypes: '='
      gradingStyles: '='
      targetMembershipId: '='
    controller: (
      $scope,
      $rootScope,
      $modal,
      $http,
      MembershipIntervalUnits,
      MembershipPlanService,
      MembershipPlans,
      EventTypes,
      EventService,
      loadingAnimation
    ) ->
      $scope.membershipPlan = $scope.newMembershipPlan
      $scope.membership_interval_unit_pairs = _.pairs MembershipIntervalUnits
      $scope.gym = $rootScope.gym
      $scope.isMartialArts = $rootScope.gym.is_martial_arts
      $scope.membership.future_booking_term_unit ||= 'weeks'

      $scope.isTypeSelected = (id) ->
        id in $scope.membership.event_type_ids

      $scope.isStyleSelected = (id) ->
        id in $scope.membership.style_ids

      $scope.toggleType = (id) ->
        if $scope.isTypeSelected(id)
          $scope.membership.event_type_ids =
            _.without($scope.membership.event_type_ids, id)
        else
          $scope.membership.event_type_ids.push(id)

      $scope.toggleStyle = (id) ->
        if $scope.isStyleSelected(id)
          $scope.membership.style_ids =
            _.without($scope.membership.style_ids, id)
        else
          $scope.membership.style_ids.push(id)

      reloadEventTypes = ->
        $scope.eventTypes = EventTypes.getList(count: 100).$object

      $scope.manageTypes = ->
        roomModal = $modal.open
          template: require('templates/gyms/calendar/events/manage_types.html.slim')
          controller: 'ManageEventTypesModal'
          resolve:
            types: ->
              $scope.eventTypes

        roomModal.result.then ->
          reloadEventTypes()
        , ->
          reloadEventTypes()

      $scope.loadDefaultDates = (useSelectedDate) ->
        uuid = $scope.membership?.membership_plan?.uuid
        return unless uuid
        params =
          gid: $rootScope.gym.id
        if useSelectedDate
          params.purchase_date = $scope.membership.start_date
        $http.get("/membership_defaults/#{uuid}", params: params).then (response) ->
          $scope.membership.start_date = response.data.start_date
          $scope.membership.upfront_payment_on = response.data.upfront_payment_on
          $scope.membership.recurring_payments_start_on = response.data.recurring_payments_start_on

      $scope.onMembershipPlanSelected = ->
        if $scope.membership.membership_plan.id
          loadingAnimation.show()
          MembershipPlans.get($scope.membership.membership_plan.id).then (plan) ->
            angular.extend $scope.membership.membership_plan, plan.plain()
            angular.extend(
              $scope.membership,
              getMembershipPlanParams(),
              create_plan_from_membership: undefined
            )
            startDate = MembershipPlanService.getStartDate(plan)
            if startDate
              $scope.membership.start_date = startDate
            $scope.loadDefaultDates()
            loadingAnimation.hide()
          , () ->
            loadingAnimation.hide()
        else
          angular.extend(
            $scope.membership,
            MembershipPlanService.build($scope.newMembershipPlan),
            create_plan_from_membership: true
          )

      if $scope.targetMembershipId && !$scope.membership.membership_plan
        $scope.membership.membership_plan =
          _.find $scope.membershipPlans, (plan) ->
            plan.id is $scope.targetMembershipId
        $scope.onMembershipPlanSelected()

      getMembershipPlanParams = ->
        membership = _.omit $scope.membership.membership_plan, [
          'id'
          'links'
          'errors'
          'uuid'
          'position'
        ]
        if membership.category
          membership.category_id = membership.category.id
        membership.not_classes_interval =
          membership.classes_unlimited || !membership.classes_interval_number
        membership

      $scope.recurringBookingsDisabled = ->
        !$scope.membership.classes_unlimited && (!$scope.membership.classes_interval_number || $scope.membership.not_classes_interval)

      $scope.onClassesIntervalChanged = ->
        $scope.membership.classes_interval_number = 0
        $scope.membership.allow_recurring_bookings = false

      $scope.onClassesLimitChange = ->
        if $scope.membership.classes_limit > 0
          $scope.membership.classes_unlimited = false

      $scope.onClassesIntervalNumberChange = ->
        if $scope.membership.classes_interval_number > 0
          $scope.membership.not_classes_interval = false

      $scope.onClassesLimitTypeChanged = ->
        $scope.membership.classes_limit = 0
        if $scope.membership.classes_unlimited
          $scope.membership.classes_interval_number = 0
          $scope.membership.not_classes_interval = true
          $scope.membership.future_booking_term_unit ||= 'weeks'
        else
          $scope.membership.restrict_future_bookings_by_count = false
          $scope.membership.restrict_future_bookings_by_date = false
          $scope.membership.future_bookings_count_limit = null
          $scope.membership.future_booking_term_number = null
          $scope.membership.future_booking_term_unit = null
          $scope.membership.allow_recurring_bookings = false

      $scope.openMembershipPlanCategoryManagement = ->
        eventModal = $modal.open
          template: require('templates/gyms/membership_plans/membershipPlanCategoryManagement.html.slim')
          controller: 'MembershipPlanCategoryManagementCtrl'

        eventModal.result.then ->
          $scope.membershipPlanCategories.reload()
        , ->
          $scope.membershipPlanCategories.reload()

      $scope.displayFutureBookingLimitsDescription = (event, type) ->
        event.preventDefault()
        $modal.open
          template: require('templates/components/membership_plan/future_booking_limits.html.slim')
          controller: 'FutureBookingLimitsModal'
          resolve:
            type: () -> type

      $scope.displayRecurringBookingsDescription = (event) ->
        event.preventDefault()
        EventService.displayRecurringBookingsDescription()

      $scope.displayTrialMembershipDescription = (event) ->
        event.preventDefault()
        MembershipPlanService.displayTrialMembershipDescription()
