import { stringifyParams } from 'helpers/query_params'
import underscored from 'underscore.string/underscored';

angular
  .module 'gym.components'
  .directive 'invoicesList', ->
    restrict: 'EA'
    template: require('templates/components/invoices/invoices_list.html.slim')
    scope:
      contact: '='
      familyContact: '='
      isSharingPayments: '='
      addCreditVoucher: '&'
    controller: (
      $scope,
      $rootScope,
      $stateParams,
      $state,
      $window,
      $filter,
      $modal,
      $http,
      $timeout,
      EmploymentService,
      TableService,
      MembershipPlans,
      Contacts,
      Locations,
      GTMService,
      MembershipPlanCategories,
      Invoices,
      BulkInvoices,
      InvoiceRescheduleService,
      MasqueradingService,
      FeatureAvailabilityService,
      bulkMessageService,
      MessageService,
      flash,
      CwStorage,
      loadingAnimation,
      Sales,
      InvoiceFailureReasonService,
      ReactModal,
      SkipInvoiceService
    ) ->
      gymId = $stateParams.gymId
      $scope.gym = gym = $rootScope.gym
      $scope.contactId = $stateParams.contactId
      $scope.permissions = EmploymentService.get().permissions
      $scope.selectedInvoices = []
      $scope.bulkRescheduleParams = {}
      $scope.currency_symbol = $rootScope.gym.currency_symbol
      $scope.paymentStatuses = [
        'upcoming',
        'submitted',
        'pending',
        'paid',
        'failed',
        'chargeback',
        'skipped',
        'process_manually',
        'auto_reschedule'
      ]

      defaultDateFilters =
        after: moment().subtract(31, 'days').format('YYYY-MM-DD'),
        before: moment().add(28, 'days').format('YYYY-MM-DD')
        type: 'due_date'
        beforeKey: 'due_date_before'
        afterKey: 'due_date_after'

      buildPresetFilterOptions = () ->
        isInitialFilters = _.isEqual($scope.filters, defaultFilters) && _.isEqual($scope.dateFilters, defaultDateFilters)
        dueNextOptionVisible = !isInitialFilters || $scope.selectedPresetFilter is 'due_next'
        $scope.presetFilterOptions = _.compact([
          {
            value: 'all_failures',
            label: 'All failures'
          },
          {
            value: 'all_skipped',
            label: 'All skipped payments'
          },
          {
            value: 'all_pending',
            label: 'All pending payments'
          },
          {
            value: 'successful_payments_14_days',
            label: 'Successful payments (last 14 days)'
          },
          {
            value: 'successful_payments_30_days',
            label: ' Successful payments (last 30 days)'
          },
          {
            value: 'successful_payments_90_days',
            label: 'Successful payments (last 90 days)'
          },
          dueNextOptionVisible && {
            value: 'due_next',
            label: 'Due next / Recently processed'
          }
        ])

      buildPresetFilterOptions()

      $scope.dateFilters =
        after: moment().subtract(31, 'days').format('YYYY-MM-DD'),
        before: moment().add(28, 'days').format('YYYY-MM-DD')

      $scope.dateFilterOptions = _.compact([
        {
          type:'due_date'
          beforeKey: 'due_date_before'
          afterKey: 'due_date_after'
        },
        {
          type:'date_paid'
          beforeKey: 'paid_before'
          afterKey: 'paid_after'
        },
        {
          type:'date_failed'
          beforeKey: 'failed_before'
          afterKey: 'failed_after'
        },
        $scope.gym.uses_ezidebit && {
          type:'date_settled'
          beforeKey: 'settled_before'
          afterKey: 'settled_after'
        }
      ])

      $scope.onDateChange = ->
        $scope.clearSelectedPreset()
        reloadTable()

      $scope.setDateFilter = (filter, silent) ->
        $scope.dateFilters.type = filter.type
        $scope.dateFilters.beforeKey = filter.beforeKey
        $scope.dateFilters.afterKey = filter.afterKey
        unless silent
          $scope.onDateChange()

      $scope.setDateFilter($scope.dateFilterOptions[0], true)

      buildDateFilters = ->
        params = {}
        params["filters[#{$scope.dateFilters.afterKey}]"] =  $scope.dateFilters.after
        params["filters[#{$scope.dateFilters.beforeKey}]"] = $scope.dateFilters.before
        params

      buildDateFiltersObj = ->
        params = {}
        params[$scope.dateFilters.afterKey] =  $scope.dateFilters.after
        params[$scope.dateFilters.beforeKey] = $scope.dateFilters.before
        params

      if !$scope.contactId
        GTMService.trackPage('Payments')

      $scope.selectPreset = () ->
        $scope.dateFilters.after = undefined
        $scope.dateFilters.before = gym.today
        presetFilters = angular.extend {}, defaultFilters
        presetFilters.search = ''

        switch $scope.selectedPresetFilter
          when 'all_failures'
            $scope.setDateFilter($scope.dateFilterOptions[2], true)
            $scope.paymentStatuses.forEach (status) ->
              presetFilters[status] = status is 'failed'
          when 'all_skipped'
            $scope.setDateFilter($scope.dateFilterOptions[0], true)
            $scope.paymentStatuses.forEach (status) ->
              presetFilters[status] = status is 'skipped'
          when 'all_pending'
            $scope.setDateFilter($scope.dateFilterOptions[0], true)
            $scope.paymentStatuses.forEach (status) ->
              presetFilters[status] = status is 'pending'
          when 'successful_payments_14_days'
            $scope.dateFilters.after = moment(gym.today).subtract(14, 'days').format('YYYY-MM-DD')
            $scope.setDateFilter($scope.dateFilterOptions[1], true)
            $scope.paymentStatuses.forEach (status) ->
              presetFilters[status] = status is 'paid'
          when 'successful_payments_30_days'
            $scope.dateFilters.after = moment(gym.today).subtract(30, 'days').format('YYYY-MM-DD')
            $scope.setDateFilter($scope.dateFilterOptions[1], true)
            $scope.paymentStatuses.forEach (status) ->
              presetFilters[status] = status is 'paid'
          when 'successful_payments_90_days'
            $scope.dateFilters.after = moment(gym.today).subtract(90, 'days').format('YYYY-MM-DD')
            $scope.setDateFilter($scope.dateFilterOptions[1], true)
            $scope.paymentStatuses.forEach (status) ->
              presetFilters[status] = status is 'paid'
          when 'due_next'
            $scope.dateFilters.after = moment(gym.today).subtract(31, 'days').format('YYYY-MM-DD')
            $scope.dateFilters.before = moment(gym.today).add(28, 'days').format('YYYY-MM-DD')
            $scope.setDateFilter($scope.dateFilterOptions[0], true)
            $scope.paymentStatuses.forEach (status) ->
              presetFilters[status] = true

        if _.isEqual($scope.filters, presetFilters)
          reloadTable()
        else
          $scope.$emit 'invoices:filtersPresetSelected', presetFilters
          $scope.setFilters(presetFilters, fromPreset: true)

      unless $scope.gym.uses_submitted_payment_status
        $scope.paymentStatuses = _.without $scope.paymentStatuses, 'submitted'
      unless $scope.gym.uses_chargeback_payment_status
        $scope.paymentStatuses = _.without $scope.paymentStatuses, 'chargeback'
      unless $scope.gym.has_payment_provider
        $scope.paymentStatuses = _.without $scope.paymentStatuses, 'auto_reschedule'

      $scope.membershipPlans = MembershipPlans.getList(count: 200).$object
      $scope.locations = []
      $scope.membershipPlanCategories = []
      $scope.creditVouchersAvailable = FeatureAvailabilityService.isAvailable('credit_vouchers')

      Locations.getList(count: 100).then (locations) ->
        $scope.locations = locations.concat({id: '-1', name: '[No location]'})

      MembershipPlanCategories.getList(count: 100).then (categories) ->
        $scope.membershipPlanCategories = categories.concat({id: '-1', label: '[No category]'})

      $scope.isContactPage = ->
        !!$scope.contactId && !$scope.familyContact

      $rootScope.$on 'invoices:list:reload', ->
        reloadTable()

      $scope.open = (invoice, event) ->
        event.preventDefault()
        event.stopPropagation()
        invoice.rescheduling = true

      $scope.isInvoiceSelected = (invoice) ->
        _.find $scope.selectedInvoices, (item) ->
          item.id is invoice.id

      $scope.toggleSelectableInvoices = (event) ->
        event.preventDefault()
        $scope.selectedInvoices =
          if $scope.selectedInvoices.length then [] else getSelectableInvoices()
        $timeout ->
          $scope.isAllInvoicesSelected = !!$scope.selectedInvoices.length

      $scope.toggleInvoice = (invoice) ->
        if $scope.isInvoiceSelected(invoice)
          $scope.selectedInvoices = _.without $scope.selectedInvoices, invoice
        else
          $scope.selectedInvoices.push invoice
        $scope.isAllInvoicesSelected =
          getSelectableInvoices().length is $scope.selectedInvoices.length

      getSelectableInvoices = ->
        _.filter $scope.tableParams.data, $scope.isInvoiceSelectable

      pluralizeInvoice = ->
        length = $scope.selectedInvoices.length
        if length > 1 then "#{length} invoices" else 'this invoice'

      $scope.getFailureReasonTooltipType = (authorizationMessage) ->
        InvoiceFailureReasonService.getFailureReasonTooltipType(authorizationMessage)

      $scope.displayFailureReasonDetails = (authorizationMessage) ->
        InvoiceFailureReasonService.displayFailureReasonDetails(authorizationMessage)

      $scope.displayAppliedCredit = (invoice) ->
        modalInstance = ReactModal.open(
          component: 'DisplayAppliedCreditModal'
          props:
            invoice: invoice
        )

      $scope.bulkMarkAsSkipped = ->
        message = ["Are you sure you want to skip #{pluralizeInvoice()}?"]
        totalCreditAmount = $scope.selectedInvoices.reduce (acc, item) =>
          if item.credit_applied_amount
            return (+acc + +item.credit_applied_amount).toFixed(2)
          return acc
        , 0

        if totalCreditAmount > 0
          creditAmount = $filter('currency')(totalCreditAmount, $scope.gym.currency_symbol)
          message.push("Note: a combined total of #{creditAmount} credit has been applied to one or more of these payments. If you wish to remove this credit, please do so from the payment actions menu.")
        MessageService.message(
          title: 'Skip invoices',
          message: message,
          confirmButtonText: "Yes"
        ).then () ->
          bulkStatusChange(property: 'skip')

      $scope.bulkMarkAsPaid = (type) ->
        modalInstance = $modal.open
          template: require('templates/gyms/invoices/bulk_mark_as_paid_modal.html.slim')
          controller: 'BulkMarkAsPaidCtrl'
          resolve:
            selectedInvoices: -> $scope.selectedInvoices
            type: -> type
            bulkStatusChange: -> bulkStatusChange

      bulkStatusChange = (options) ->
        params =
          ids: _.map $scope.selectedInvoices, (invoice) -> invoice.id
          invoices:
            approval_date: options.approval_date
          withoutNamespace: true
        params.invoices[options.property] = true
        if options.onLoading
          options.onLoading()

        loadingAnimation.show()
        BulkInvoices.doPUT(params).then ->
          status = if options.property is 'skip' then 'skipped' else "paid by #{options.label}"
          invoiceNaming = if params.ids.length > 1 then 'invoices' else 'invoice'
          flash.success = "The #{invoiceNaming} was successfully marked as #{status}"
          reloadTable()
          if options.onSuccess
            options.onSuccess()
        , (response) ->
          loadingAnimation.hide()
          flash.error = "There was an error processing the request."
          if options.onError
            options.onError(response)

      $scope.reschedule = (invoice) ->
        invoice.rootObjectName = 'invoice'
        newDueDate = $filter('stringToDate') invoice.rescheduled_due_date
        if $window.confirm "Are you sure you want to reschedule this invoice to #{newDueDate}?"
          InvoiceRescheduleService.reschedule(invoice, reloadTable)
        else
          invoice.rescheduled_due_date = invoice.due_date

      $scope.bulkReschedule = ->
        rescheduleDate = $scope.bulkRescheduleParams.date
        $scope.bulkRescheduleParams.date = undefined
        dateLabel = $filter('stringToDate') rescheduleDate
        contactIds = _.map $scope.selectedInvoices, (invoice) -> invoice.contact_id

        if $scope.gym.has_payment_provider and moment().isAfter(rescheduleDate, 'day')
          flash.error = 'Invoices cannot be rescheduled to a date before today.'
          return

        if contactIds.length isnt _.uniq(contactIds).length
          flash.error = 'Please select at most one payment per member before rescheduling.'
          return

        if $window.confirm "Are you sure you want to reschedule #{pluralizeInvoice()} to #{dateLabel}?"
          InvoiceRescheduleService
            .bulkReschedule($scope.selectedInvoices, rescheduleDate, dateLabel, reloadTable)

      $scope.isBulkRescheduleFromDateAvailable = (invoice) ->
        $scope.permissions.manage_payment_status && invoice.status is 'upcoming' &&
          moment(invoice.due_date).isAfter($scope.gym.today) && !invoice.sale_id

      $scope.bulkRescheduleFromDate = (invoice) ->
        params =
          contactType: $scope.contactsType(invoice.contact_type)
          contactId: invoice.contact_id
          invoice_id: invoice.invoice_id
          from_contact_page: !!$scope.contactId || undefined
        $state.go 'gym.contacts.membershipRealignments', params

      $scope.setOrder = (field) ->
        return if $scope.isContactPage()
        orderBy = {}
        orderBy[field] =
          if $scope.orderBy[field] is 'asc' then 'desc' else 'asc'
        $scope.orderBy = orderBy
        $scope.tableParams.reload()

      $scope.markAsPaid = (invoice, type) ->
        modalInstance = $modal.open
          template: require('templates/gyms/invoices/mark_as_paid_modal.html.slim')
          controller: 'MarkAsPaidCtrl'
          resolve:
            invoice: -> invoice
            type: -> type

        modalInstance.result.then (data) ->
          reloadTable()

      $scope.skip = (invoice) ->
        SkipInvoiceService.skip(invoice).then () ->
          reloadTable()

      $scope.adminChangeToPaid = (invoice) ->
        message = "WARNING: a pending payment's status should only be changed
          if there is no way to get the status back from the provider normally.
          Once this change has been made, Clubworx will no longer be able to
          query the payment provider for this payment's status."

        if $window.confirm message
          invoice.doPUT({}, 'admin_status_change_paid').then ->
            flash.success = 'Invoice marked as paid'
            reloadTable()
          , () ->
            flash.error = "Request could not be completed. Please try again"

      $scope.adminChangeToFailed = (invoice) ->
        message = "WARNING: a pending payment's status should only be changed
          if there is no way to get the status back from the provider normally.
          Once this change has been made, Clubworx will no longer be able to
          query the payment provider for this payment's status."

        if $window.confirm message
          invoice.doPUT({}, 'admin_status_change_failed').then ->
            flash.success = 'Invoice marked as failed'
            reloadTable()
          , () ->
            flash.error = "Request could not be completed. Please try again"

      $scope.isChangeAmountAvailable = (invoice) ->
        $scope.permissions.adjust_payment_amounts and (
          (invoice.payment_plan_version is 'v1_payment_plan' and invoice.status is 'upcoming' and moment($scope.gym.today).isBefore(invoice.due_date)) or
          (invoice.payment_plan_version is 'v2_invoice_plan' and ['upcoming', 'process_manually'].includes(invoice.status))
        )

      $scope.isSplitPaymentAvailable = (invoice) ->
        invoice.status is 'upcoming' &&
          $scope.permissions.adjust_payment_amounts &&
            invoice.payment_plan_version is 'v2_invoice_plan' &&
              invoice.payment_type is 'regular_payment'

      $scope.isApplyCreditAvailable = (invoice) ->
        invoice.status is 'upcoming' && $scope.permissions.adjust_payment_credit && $scope.creditVouchersAvailable

      $scope.isRemoveCreditAvailable = (invoice) ->
        $scope.creditVouchersAvailable && invoice.credit_applied_amount > 0 && invoice.status not in ['pending', 'submitted'] &&
          $scope.permissions.adjust_payment_credit && invoice.paid_with_noun isnt 'Credit balance'

      $scope.isChangeStatusAsAdminAvailable = (invoice) ->
        invoice.status is 'pending' && MasqueradingService.isMasquerading()

      $scope.isRefund = (invoice) ->
        invoice.payment_type in ['full_refund' , 'partial_refund']

      $scope.isRefundAvailable = (invoice) ->
        invoice.status is 'paid' &&
          !invoice.non_debit_payment &&
          invoice.row_type isnt 'sale' &&
          $scope.permissions.adjust_payment_amounts &&
          !$scope.isRefund(invoice)

      $scope.isDeleteAvailable = (invoice) ->
        ($scope.permissions.adjust_payment_amounts && invoice.non_debit_payment) ||
         ($scope.permissions.view_pos_sales && invoice.sale_id && !invoice.invoice_id)

      $scope.isReceiptAvailable = (invoice) ->
        invoice.status is 'paid' &&
          invoice.membership_send_invoice_receipts

      $scope.isPosReceiptAvailable = (invoice) ->
        invoice.sale_id && !invoice.membership_send_invoice_receipts && !invoice.non_debit_payment

      $scope.isRescheduleAvailable = (invoice) ->
        $scope.permissions.manage_payment_status && !invoice.realtime &&
        ($scope.isInvoiceEditable(invoice) || invoice.non_debit_payment)

      $scope.isStatusChangeAvailable = (invoice) ->
        $scope.isInvoiceEditable(invoice) && !invoice.non_debit_payment

      $scope.isMarkAsPaidAvailable = (invoice) ->
        return false unless $scope.permissions.manage_payment_status
        $scope.isStatusChangeAvailable(invoice) || invoice.status is 'skipped'

      $scope.isBulkStatusChangeAvailable = ->
        $scope.selectedInvoices.length &&
          $scope.permissions.manage_payment_status &&
          !_.find($scope.selectedInvoices, (invoice) -> invoice.non_debit_payment)

      $scope.isAdjustmentAvailable = ->
        $scope.isContactPage() && $scope.contact.memberships?.length

      $scope.isRetryNowAvailable = (invoice) ->
        $scope.permissions.manage_payment_status &&
          $scope.gym.realtime_failure_retries_enabled &&
          invoice.status is 'failed'

      $scope.isProcessNowAvailable = (invoice) ->
        moment(invoice.due_date).isSameOrBefore(moment().add(30, 'days'), 'day') &&
          $scope.permissions.manage_payment_status &&
          $scope.gym.realtime_failure_retries_enabled &&
          invoice.status is 'upcoming'

      $scope.isMarkAsFailedAvailable = (invoice) ->
        !$scope.gym.has_payment_provider &&
          invoice.status is 'process_manually' &&
          $scope.permissions.manage_payment_status

      $scope.checkPaymentStatusAvailable = (invoice) ->
        invoice.can_trigger_individual_status_check && MasqueradingService.isMasquerading()

      $scope.fetchEzidebitPaymentDetailsAvailable = (invoice) ->
        MasqueradingService.isMasquerading() && invoice.payment_provider_name is 'Ezidebit' && invoice.provider_payment_reference

      $scope.markAsFailed = (invoice) ->
        if $window.confirm "Are you sure you want to mark this invoice as failed?"
          url = "/gyms/#{$scope.gym.id}/invoices/#{invoice.id}/mark_failed"
          $http.put(url).then ->
            flash.success = 'Invoice marked as failed'
            reloadTable()
          , () ->
            flash.error = "Request could not be completed. Please try again"

      $scope.retryNow = (invoice) ->
        if invoice.realtime is true
          modalInstance = $modal.open
            template: require('templates/gyms/invoices/deny_failed_payment_retry.modal.html.slim')
            controller: 'DenyFailedPaymentRetryCtrl'

        else
          modalInstance = $modal.open
            template: require('templates/gyms/invoices/retry_failed_payment_modal.html.slim')
            controller: 'RetryFailedPaymentCtrl'
            resolve:
              invoice: -> invoice
              paymentMethods: (Restangular, $stateParams) ->
                Restangular
                  .one('gyms', $stateParams.gymId)
                  .one('contacts', invoice.contact_id)
                  .all('ezidebit').getList()

        modalInstance.result.then (data) ->
          reloadTable()


      $scope.processNow = (invoice) ->
        modalInstance = $modal.open
          template: require('templates/gyms/invoices/process_payment_modal.html.slim')
          controller: 'ProcessPaymentCtrl'
          resolve:
            invoice: -> invoice
            paymentMethods: (Restangular, $stateParams) ->
              Restangular
                .one('gyms', $stateParams.gymId)
                .one('contacts', invoice.contact_id)
                .all('ezidebit').getList()

        modalInstance.result.then (data) ->
          reloadTable()

      $scope.checkPaymentStatus = (invoice) ->
        if window.confirm "Are you sure?"
          url = "/gyms/#{$scope.gym.id}/invoices/#{invoice.id}/trigger_status_check"
          $http.put(url).then () ->
            flash.success = "Payment status check successful"
            reloadTable()
          , () ->
            flash.error = "An error occurred while checking payment status"

      $scope.fetchEzidebitPaymentDetails = (invoice) ->
        ReactModal.open(
          component: 'FetchEzidebitPaymentDetailsModal'
          props:
            invoice: invoice
        )

      $scope.isDelayedLinkVisible = (invoice) ->
        $scope.gym.uses_submitted_payment_status &&
          invoice.charged_on && moment(invoice.charged_on).isAfter(invoice.due_date) &&
          !(invoice.status in ['upcoming', 'skipped'])

      $scope.isMoreInfoAvailable = (invoice) ->
        !(invoice.status in ['skipped'] || invoice.non_debit_payment)

      $scope.showMoreInfo = (invoice) ->
        modalInstance = $modal.open
          template: require('templates/gyms/invoices/invoice_info_modal.html.slim')
          resolve:
            invoice: -> invoice
            reloadTable: -> reloadTable
            refund: -> $scope.isRefund(invoice)
          controller: 'InvoiceInfoCtrl'

      $scope.canSendMessage = (invoice) ->
        $scope.permissions.send_comms && invoice.contact_id && !$scope.isRefund(invoice)

      $scope.sendMessage = (invoice) ->
        Contacts.get("#{invoice.contact_id}").then (invoiceContact) ->
          recipient =
            id: invoice.contact_id
            name: invoice.contact_name
            type: invoiceContact.type
            status: invoiceContact.status
          bulkMessageService.setRecipients [recipient]
          params =
            invoiceId: invoice.id
          if $stateParams.contactId
            params.from = 'contactInvoices'
            params.fromContactId = $stateParams.contactId
            params.fromContactType = $stateParams.contactType
          else
            params.from = 'invoices'
          $state.go 'gym.bulkMessages.new', params

      $scope.addAdjustment = ->
        modalInstance = $modal.open
          template: require('templates/gyms/invoices/add_adjustment_modal.html.slim')
          controller: 'AddAdjustmentCtrl'
          resolve:
            contact: -> $scope.contact

        modalInstance.result.then (data) ->
          reloadTable()

      $scope.addCredit = ->
        MessageService.message(
          title: 'Add credit to contact'
          message: [
            "<strong>Credit vouchers</strong> can be used to apply a credit balance to a customer, which can then be used to pay for (or reduce the chargeable amount of) selected payments.",
            'Click "Add Credit" below to add a voucher for this contact now.'
          ]
          bindHtml: true
          confirmButtonText: 'Add Credit'
          cancelButtonText: 'Cancel'
        ).then () ->
          $scope.addCreditVoucher()

      $scope.isCancelAvailable = (invoice) ->
        $scope.permissions.manage_payment_status && invoice.status is 'submitted'

      $scope.cancelInvoice = (invoice) ->
        modalInstance = $modal.open
          template: require('templates/gyms/invoices/cancel_invoice.html.slim')
          resolve:
            invoice: ->
              invoice
          controller: 'InvoiceCancellationCtrl'

        modalInstance.result.then (data) ->
          reloadTable()

      remove = (invoice) ->
        if invoice.sale_id && !invoice.invoice_id
          $http.delete("gyms/#{gymId}/pos/sales/#{invoice.sale_id}")
        else
          invoice.remove()

      $scope.delete = (invoice) ->
        if $window.confirm 'Are you sure you want to delete this invoice?'
          loadingAnimation.show()
          remove(invoice).then ->
            flash.success = 'Invoice deleted'
            reloadTable()

      $scope.invoiceReceiptLink = (invoice) ->
        "/gyms/#{gymId}/invoices/#{invoice.id}"

      $scope.posReceiptLink = (invoice) ->
        "/gyms/#{gymId}/pos/sales/#{invoice.sale_id}/receipt"

      $scope.resendInvoiceEmail = (invoice) ->
        if $window.confirm 'Are you sure you want to re-send this payment receipt?'
          url = "/gyms/#{$scope.gym.id}/invoices/#{invoice.id}/resend_receipt_email"
          $http.put(url).then (response) ->
            email = response.data.contact_email
            flash.success = "Invoice receipt email sent to: #{email}"
          , ->
            flash.error = 'An error occurred sending invoice email to member.'

      $scope.contactsType = (type) ->
        "#{underscored(type)}s"

      $scope.changeAmount = (invoice) ->
        modalInstance = $modal.open
          template: require('templates/gyms/invoices/change_amount_modal.html.slim')
          resolve:
            invoice: ->
              invoice
            currency: ->
              $scope.currency_symbol
          controller: 'PaymentAdjustmentsCtrl'

        modalInstance.result.then (data) ->
          reloadTable()

      $scope.splitPayment = (invoice) ->
        modalInstance = ReactModal.open(
          component: 'SplitPaymentModal'
          props:
            invoice: invoice
        )
        modalInstance.then (data) ->
          $scope.$emit 'contact:reload'
          reloadTable()

      $scope.applyCredit = (invoice) ->
        modalInstance = ReactModal.open(
          component: 'ApplyCreditModal'
          props:
            invoice: invoice
        )
        modalInstance.then (data) ->
          $scope.$emit 'contact:reload'
          reloadTable()

      $scope.removeCredit = (invoice) ->
        ReactModal.open(
          component: 'RemoveCreditModal'
          props:
            invoice: invoice
        )

      $scope.refund = (invoice) ->
        if $scope.gym.uses_ezidebit && $scope.gym.refunds_enabled && invoice.payment_provider_name is 'Ezidebit'
          ReactModal.open(
            component: 'EzidebitRefundModal'
            props:
              invoice: invoice
          ).then (data) ->
            reloadTable()
        else
          modalInstance = $modal.open
            template: require('templates/gyms/invoices/invoice_refund_modal.html.slim')
            resolve:
              invoice: ->
                invoice
              currency: ->
                $scope.currency_symbol
            controller: 'InvoiceRefundCtrl'

          modalInstance.result.then (data) ->
            reloadTable()

      $scope.isRevertPaidAvailable = (invoice) ->
        invoice.revertable_from_paid && $scope.permissions.manage_payment_status

      $scope.isRevertSkippedAvailable = (invoice) ->
        invoice.revertable_from_skipped && $scope.permissions.manage_payment_status

      $scope.revertPaidToUpcoming = (invoice) ->
        message = if moment(invoice.due_date).isBefore(moment(), 'day')
          "This will change payment #{invoice.invoice_number_base} back to
          Upcoming.\nNote: because this payment was due before today, it will
          be rescheduled as part of this change."
        else
          "This will change payment #{invoice.invoice_number_base} back to Upcoming."

        if $window.confirm message
          invoice.doPUT({}, 'revert_paid').then (response) ->
            reloadTable()
            flash.success = if response.due_date is invoice.due_date
              "Payment change successful"
            else
              newDueDate = $filter('stringToDate') response.due_date
              "Payment change successful (payment was rescheduled to
              #{newDueDate} as part of this change)."

      $scope.revertSkippedToUpcoming = (invoice) ->
        message = "This will change payment #{invoice.invoice_number_base} back to Upcoming."

        if $window.confirm message
          invoice.doPUT({}, 'revert_skipped').then (response) ->
            reloadTable()
            flash.success = "Payment change successful"

      $scope.getCsv = () ->
        GTMService.trackPage('Payments', 'Export')
        params =
          filters: angular.extend {}, $scope.filters, buildDateFiltersObj()
          sorting: $scope.orderBy
        params.contact_id = $scope.contactId if $scope.contactId
        params.family = true if $scope.familyContact
        serializedParams = angular.element.param(params)
        exportPath = Invoices.all('export').getRestangularUrl()
        url = "#{exportPath}.csv?#{serializedParams}"
        $window.open url, '_blank'
        return true

      $scope.showPaymentProviderNotice =
        !$scope.gym.has_payment_provider && !CwStorage.getItem('hidePaymentProviderNotice')

      $scope.hidePaymentProviderNotice = ->
        CwStorage.setItem('hidePaymentProviderNotice', true)
        $scope.showPaymentProviderNotice = false

      $scope.isInvoiceEditable = (invoice) ->
        $scope.permissions.manage_payment_status &&
          (
            invoice.status in ['upcoming', 'failed', 'process_manually'] ||
            invoice.non_debit_payment
          )

      $scope.isInvoiceSelectable = (invoice) ->
        if $scope.isContactPage()
          $scope.permissions.manage_payment_status &&
            invoice.status in ['upcoming', 'failed', 'process_manually']
        else
          $scope.isInvoiceEditable(invoice)

      $scope.rescheduleMinDate = (invoice) ->
        $scope.gym.today

      defaultFilters =
        membership_plan_ids: []
        location_ids: []
        category_ids: []
        sources: []
        payment_method: null
        refunds_only: false
        exclude_refunds: false
        search: ''
      $scope.paymentStatuses.forEach (status) ->
        defaultFilters[status] = true

      storedFilters = JSON.parse(CwStorage.getItem('invoicesListFilters'))
      $scope.filters = if storedFilters && !$scope.isContactPage()
        _.defaults storedFilters, defaultFilters
      else
        defaultFilters

      $scope.clearSelectedPreset = () ->
        $scope.selectedPresetFilter = ''

      $scope.setFilters = (newFilters, params) ->
        unless params?.fromPreset
          $scope.clearSelectedPreset()
        angular.extend $scope.filters, newFilters
        onFiltersChanged()

      onFiltersChanged = ->
        unless $scope.isContactPage()
          CwStorage.setItem('invoicesListFilters', JSON.stringify($scope.filters))

      $scope.resendPosSaleReceipt = (invoice) ->
        $modal.open
          template: require('templates/gyms/pos/sales/resend.html.slim')
          controller: 'ResendSaleModalCtrl'
          resolve:
            sale: (Sales) -> Sales.get(invoice.sale_id).then (sale) ->
              sale.customer_email = sale.customer_attributes?.email
              sale

      getSources = ->
        sources = [
          FeatureAvailabilityService.isAvailable('waivers') && {id: 'waiver', name: 'Waiver'}
          FeatureAvailabilityService.isAvailable('pos') && {id: 'pos', name: 'POS sale'}
          FeatureAvailabilityService.isAvailable('member_portal') && {id: 'member_portal', name: 'Member portal'}
          $scope.gym.book_and_pay_enabled && {id: 'realtime', name: 'Book & Pay / Realtime'}
          {id: 'kiosk', name: 'Kiosk purchase'}
          FeatureAvailabilityService.isAvailable('sequences') && {id: 'sequence', name: 'Sequence'}
          FeatureAvailabilityService.isAvailable('grading_events') && {id: 'grading_event', name: 'Grading event fee'}
          {id: 'booking_cancel_fee', name: 'Booking late-cancel fee'}
          {id: 'membership_cancel_fee', name: 'Membership cancel fee'}
          $scope.gym.has_payment_provider && {id: 'failure_fee', name: 'Failed payment fee'}
          {id: 'ndp', name: 'Non-debit adjustment'}
          {id: 'membership_general', name: 'Membership added by user'}
          $scope.gym.mobile_app_enabled && {id: 'mobile_app', name: 'Mobile app'}
        ]
        _.compact sources

      $scope.sources = getSources()

      $scope.reloadTable = reloadTable = ->
        $scope.tableParams.reload()

      getTotalAmount = (invoices) ->
        _.reduce invoices, (sum, invoice) ->
          if invoice.amount
            sum + parseFloat invoice.amount
          else
            sum
        , 0

      getCountOnPage = (invoices) ->
        _.filter(invoices, (invoice) -> invoice.amount).length

      $scope.orderBy =
        due_date: 'desc'

      $scope.$watch 'filters', (->
        $scope.tableParams.page 1
      ), true

      formatCurrency = (val) ->
        $filter('cwCurrency')(val, $scope.currency_symbol)

      $scope.tableParams = TableService.init
        count: 25
        filters: $scope.filters
        getData: (resolve, params, beforeLoad) ->
          loadingAnimation.show()
          $scope.selectedInvoices = []
          $scope.isAllInvoicesSelected = false

          requestParams = params.url()
          requestParams['filters[membership_plan_ids][]'] = requestParams['filters[membership_plan_ids]']
          requestParams['filters[membership_plan_ids]'] = undefined
          requestParams['filters[location_ids][]'] = requestParams['filters[location_ids]']
          requestParams['filters[location_ids]'] = undefined
          requestParams['filters[category_ids][]'] = requestParams['filters[category_ids]']
          requestParams['filters[category_ids]'] = undefined
          requestParams['filters[sources][]'] = requestParams['filters[sources]']
          requestParams['filters[sources]'] = undefined
          requestParams.contact_id = $scope.contactId if $scope.contactId
          requestParams.family = true if $scope.familyContact
          angular.extend requestParams, buildDateFilters(), stringifyParams(sorting: $scope.orderBy)
          beforeLoad(requestParams)
          Invoices.getList(requestParams).then (data) ->
            loadingAnimation.hide()
            params.total data.meta.total
            $scope.total = data.meta.total
            $scope.meta = data.meta
            $scope.totalAmount = getTotalAmount(data)
            $scope.countOnPage = getCountOnPage(data)
            $scope.histories = _.mapObject data.meta.histories, (val) ->
              val.map (item) ->
                angular.extend {id: item.data.id, type: item.data.type}, item.data.attributes
            invoices =  _.map data, (invoice) ->
              invoice.id ||= _.uniqueId('suspension_')
              invoice.rescheduled_due_date = invoice.due_date
              invoice
            $scope.totalAmountLabel = "#{formatCurrency($scope.totalAmount)} of #{formatCurrency($scope.meta.amount_total)}"
            $scope.totalAmountCurrentPage = formatCurrency($scope.totalAmount)
            $scope.totalAmountAllPages = formatCurrency($scope.meta.amount_total)
            $scope.countOnPageLabel = "#{if $scope.countOnPage then '1 - ' else ''}#{$scope.countOnPage}"
            $scope.selectAllAvailable = _.find invoices, $scope.isInvoiceSelectable
            buildPresetFilterOptions()
            resolve invoices
          , ->
            loadingAnimation.hide()
            flash.error = 'Request couldn\'t be completed. Please try again'
