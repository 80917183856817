import rangeSelectionSaveRestore from 'helpers/range_selection_save_restore'

import {
  ALL_MERGE_TAG_LIST,
  MERGE_TAG_LIST,
  MEMBER_PORTAL_MERGE_TAG_LIST,
  MOBILE_APP_MERGE_TAG_LIST,
  PAYMENT_MERGE_TAGS,
  CREDIT_VOUCHER_MERGE_TAGS,
  MEMBERSHIP_MERGE_TAGS,
  BOOKING_MERGE_TAGS,
  GRADING_EVENT_MERGE_TAGS,
  GRADING_MERGE_TAGS,
  STYLE_MERGE_TAGS,
  SUSPENSION_MERGE_TAGS,
  RECURRING_BOOKING_MERGE_TAGS,
  LATE_CANCELLED_BOOKING_MERGE_TAGS,
  WAITLIST_ATTENDEE_MERGE_TAGS,
  PERFORMED_WORKOUT_MERGE_TAGS,
  MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST,
  MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST
} from 'helpers/merge_tags'

angular.module 'shared', [
  'textAngular'
]

.constant 'MERGE_TAG_LIST', MERGE_TAG_LIST
.constant 'MEMBER_PORTAL_MERGE_TAG_LIST', MEMBER_PORTAL_MERGE_TAG_LIST
.constant 'MOBILE_APP_MERGE_TAG_LIST', MOBILE_APP_MERGE_TAG_LIST
.constant 'MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST', MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST
.constant 'MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST', MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST
.constant 'PAYMENT_MERGE_TAGS', PAYMENT_MERGE_TAGS
.constant 'CREDIT_VOUCHER_MERGE_TAGS', CREDIT_VOUCHER_MERGE_TAGS
.constant 'MEMBERSHIP_MERGE_TAGS', MEMBERSHIP_MERGE_TAGS
.constant 'BOOKING_MERGE_TAGS', BOOKING_MERGE_TAGS
.constant 'GRADING_EVENT_MERGE_TAGS', GRADING_EVENT_MERGE_TAGS
.constant 'GRADING_MERGE_TAGS', GRADING_MERGE_TAGS
.constant 'STYLE_MERGE_TAGS', STYLE_MERGE_TAGS
.constant 'SUSPENSION_MERGE_TAGS', SUSPENSION_MERGE_TAGS
.constant 'RECURRING_BOOKING_MERGE_TAGS', RECURRING_BOOKING_MERGE_TAGS
.constant 'LATE_CANCELLED_BOOKING_MERGE_TAGS', LATE_CANCELLED_BOOKING_MERGE_TAGS
.constant 'WAITLIST_ATTENDEE_MERGE_TAGS', WAITLIST_ATTENDEE_MERGE_TAGS
.constant 'PERFORMED_WORKOUT_MERGE_TAGS', PERFORMED_WORKOUT_MERGE_TAGS

.config (
  $provide
) ->

  $provide.decorator "taOptions",
    (taRegisterTool, $delegate, AssetLibraryService) ->

      taRegisterTool "hr",
        buttontext: "Line"
        action: ->
          tag = "<hr/>"
          if window.navigator.userAgent.indexOf('Firefox') >= 0
            tag = tag + ' '
          @$editor().wrapSelection "inserthtml", tag

      taRegisterTool "insertHttpsLink",
        iconclass: 'icon-link',
        tooltiptext: 'Insert link'
        action: () ->
          url = window.prompt('Please enter a URL to insert:', 'https://')
          if (url && url isnt '' && url isnt 'https://')
            @$editor().wrapSelection('createLink', url, true)

      taRegisterTool "insertAssetImage",
        iconclass: 'fa fa-picture-o',
        action: ->
          cursorPosition = rangeSelectionSaveRestore.saveSelection()

          AssetLibraryService.open(allowSkipAddingToLibrary: true, multiple: true).then (images) =>
            images.forEach (image) =>
              @$editor().displayElements.text.trigger('focus')
              rangeSelectionSaveRestore.restoreSelection(cursorPosition)
              url = image.attached_url || image.url
              tag = "<img src=\"#{url}\"/ data-asset-id=\"#{image.uuid}\">"
              @$editor().wrapSelection "insertHTML", tag, true

      registerTool = (name) ->
        taRegisterTool "#{name}",
          buttontext: "#{name}"
          action: ->
            tag = "<mark>{#{name}}</mark>&#160;"
            if window.navigator.userAgent.indexOf('Firefox') >= 0
              tag = tag + ' '
            @$editor().wrapSelection "inserthtml", tag

      ALL_MERGE_TAG_LIST.forEach registerTool
      $delegate
