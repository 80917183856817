import days from 'constants/days'
import hours, { hoursMap } from 'constants/hours'

angular
  .module 'gym.components'
  .directive 'sequenceStepCustomHours', ->
    restrict: 'EA'
    template: require('templates/components/sequences/sequence_step_custom_hours.html.slim')
    scope:
      condition: '='
      sequence: '='
    controller: ($rootScope, $scope, $filter) ->
      gym = $rootScope.gym
      sequenceHours = $scope.sequence.gym_sequence_hours

      $scope.days = days.map (day) ->
        id: day.value
        name: $filter('humanize')(day.label)

      $scope.hours = hours.map (hour) ->
        id: hour.value
        name: hour.label

      $scope.hoursUnitSelected = ->
        ($scope.condition.time_since_previous_step_unit is 'hours' || $scope.condition.trigger_time_unit is 'hours') &&
          !$scope.condition.immediate

      $scope.isTooltipVisible = ->
        hasRestrictedHours = sequenceHours && !(sequenceHours[0] is 0 && sequenceHours[1] is 23)
        hasRestrictedHours && !$scope.hoursUnitSelected()

      getTooltipContent = ->
        return unless $scope.isTooltipVisible()
        channels = if gym.mobile_app_enabled
          'Emails, SMSes and Push Notifications'
        else
          'Emails and SMSes'

        hoursInterval =
          "#{hoursMap[sequenceHours[0]].label} - #{hoursMap[sequenceHours[1] + 1].label}"

        """
          #{channels} will only be sent during the hours of #{hoursInterval}.
          <br/>
          All other sequence actions can happen at any time, unless restricted here.
        """

      $scope.tooltipContent = getTooltipContent()
