angular
  .module 'gym.components'
  .directive 'waiverMembershipForm', ->
    restrict: 'E'
    scope:
      waiver: '='
      currency: '='
    template: require('templates/components/waivers/waiver_membership_form.html.slim')
    controller: ($scope) ->
      $scope.familyMembershipStrategy =
        $scope.waiver.signed_waiver_form_attributes.family_membership_strategy

      groupMembershipPlans = ->
        groupMemberships = $scope.waiver.signed_waiver_form_attributes.group_memberships
        plans =
          $scope.waiver.signed_waiver_form_attributes.membership_plan_options
        _.groupBy plans, (plan) ->
          if groupMemberships
            plan.category?.label
          else
            ''

      $scope.signedAsFamily =
        $scope.waiver.signed_as is 'signed_as_adult_with_family'

      $scope.activeContacts = ->
        $scope.waiver.signed_waiver_contacts_attributes.filter (c) -> !c._destroy

      $scope.multipleContacts = ->
        $scope.activeContacts().length > 1

      $scope.displayContactMemberships = (contact, index) ->
        !$scope.signedAsFamily ||
          $scope.multipleMemberships() ||
          ($scope.familyMembershipStrategy is 'only_parent_pays_membership' && index is 0) ||
          ($scope.familyMembershipStrategy is 'every_attendee_pays_membership' && $scope.signedAsFamily && index is 1)

      $scope.multipleMemberships = ->
        $scope.signedAsFamily && $scope.familyMembershipStrategy is 'each_signee_chooses_own_membership' &&
          $scope.activeContacts().length > 1

      $scope.onMembershipChanged = (contact) ->
        contact.requested_membership_start_date = undefined
        if !$scope.multipleMemberships() && $scope.activeContacts().length > 1
          $scope.activeContacts().forEach (c) ->
            unless $scope.familyMembershipStrategy is 'every_attendee_pays_membership' && c.primary && c.attending is false
              c.membership_plan_id = contact.membership_plan_id
              c.requested_membership_start_date = undefined

      if $scope.waiver.signed_waiver_form_attributes
        $scope.groupedMemberships =
            _.chain(groupMembershipPlans()).pairs().sortBy((item) -> item[0]).value()
